export const ChartIcon = ({ className = "h-6 w-6" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  )
}

export const InvoiceIcon = ({ className = "h-6 w-6" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        strokeWidth={1.5}
        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      />
    </svg>
  )
}

export const MagnifyingGlassIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className=" mt-1 mb-1 md:mt-1 w-5 h-5 sm:w-6 sm:h-6" viewBox="0 0 48 48">
      <g id="_11_-_20" data-name="11 - 20">
        <g id="Research">
          <path d="m43.018 42.784-3.9-3.912a9.015 9.015 0 1 0 -4.243 4.242l3.911 3.9a2.994 2.994 0 1 0 4.236-4.233zm-16.968-2.834a7 7 0 1 1 9.9 0 7.007 7.007 0 0 1 -9.9 0zm15.55 5.65a1 1 0 0 1 -1.406 0l-3.581-3.573a8.489 8.489 0 0 0 1.414-1.413l3.573 3.586a.991.991 0 0 1 0 1.406z" />
          <path d="m25 44h-13a3 3 0 0 1 -3-3v-34a3 3 0 0 1 3-3h17.38a2.973 2.973 0 0 1 .62.067v5.933a3 3 0 0 0 3 3h5.923a3 3 0 0 1 .077.651v13.349a1 1 0 0 0 2 0v-13.349a4.975 4.975 0 0 0 -1.456-3.527l-6.62-6.651a4.965 4.965 0 0 0 -3.544-1.473h-17.38a5.006 5.006 0 0 0 -5 5v34a5.006 5.006 0 0 0 5 5h13a1 1 0 0 0 0-2zm12.594-33h-4.594a1 1 0 0 1 -1-1v-4.62z" />
          <path d="m14 13h11a1 1 0 0 0 0-2h-11a1 1 0 0 0 0 2z" />
          <path d="m34 17h-17a1 1 0 0 0 0 2h17a1 1 0 0 0 0-2z" />
          <path d="m35 23a1 1 0 0 0 -1-1h-20a1 1 0 0 0 0 2h20a1 1 0 0 0 1-1z" />
          <path d="m21 28a1 1 0 0 0 -1-1h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 1-1z" />
          <path d="m14 34h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2z" />
          <path d="m14 39h5a1 1 0 0 0 0-2h-5a1 1 0 0 0 0 2z" />
          <path d="m31 38a3 3 0 0 1 -3-3 1 1 0 0 0 -2 0 5.006 5.006 0 0 0 5 5 1 1 0 0 0 0-2z" />
        </g>
      </g>
    </svg>
  )
}

export const XButtonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className=" w-5 h-5 sm:w-6 sm:h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  )
}

export const PenIcon = () => {
  return (
    <svg
      className=" mt-2 md:mt-1 w-5 h-5 sm:w-6 sm:h-6"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2267 11.6002L13.0467 3.78017C13.5818 3.28158 13.802 2.53069 13.6211 1.82207C13.4401 1.11344 12.8868 0.560112 12.1781 0.379142C11.4695 0.198173 10.7186 0.418429 10.22 0.953504L2.38003 8.7935C2.32989 8.84505 2.28725 8.9034 2.25337 8.96684L0.400034 12.7068C0.298686 12.9137 0.311289 13.1582 0.433367 13.3535C0.555444 13.5488 0.7697 13.6673 1.00003 13.6668C1.11003 13.6642 1.21768 13.6345 1.31337 13.5802L5.05337 11.7268C5.1168 11.693 5.17515 11.6503 5.2267 11.6002ZM13 13.6668C13.3682 13.6668 13.6667 13.3684 13.6667 13.0002C13.6667 12.632 13.3682 12.3335 13 12.3335H7.00003C6.63184 12.3335 6.33337 12.632 6.33337 13.0002C6.33337 13.3684 6.63184 13.6668 7.00003 13.6668H13ZM9.88003 5.06017L8.94003 4.12017L3.42003 9.66684L2.49337 11.5335L4.33337 10.5802L9.88003 5.06017ZM11.6434 1.666C11.8261 1.666 12.0008 1.74102 12.1267 1.8735C12.2566 2.00209 12.328 2.17838 12.3243 2.36113C12.3205 2.54389 12.2418 2.71707 12.1067 2.84017L10.82 4.12017L9.88003 3.18017L11.16 1.8735C11.2859 1.74102 11.4606 1.666 11.6434 1.666Z"
        fill="#333333"
      />
    </svg>
  )
}

export const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  )
}

export const ArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  )
}

export const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
    </svg>
  )
}

/* <svg
                          className="h-6 w-6 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                          />
                        </svg> */
