import { graphql, useStaticQuery } from "gatsby"
import { useIsAuthenticated } from "@azure/msal-react"
import FaqSection from "../components/Sections/FaqSection"

/**
 * @description Veiledning
 * @param param0
 */

const SupportFaq = () => {
  // Sort all related articles from new to old. Let GH override with createdAtModified

  const data = useStaticQuery(graphql`
    {
      allContentfulInstruction(filter: { node_locale: { eq: "nb-NO" }, isfaq: { eq: true }, public: { eq: false } }) {
        nodes {
          id
          public
          contentful_id
          title
          slug
          updatedAt(formatString: "")
          node_locale
          createdAt
          heroImage {
            id
            public_id
            url
            secure_url
            width
            height
            format
          }
          ingress {
            ingress
            childrenMdx {
              timeToRead
            }
          }
          body {
            childrenMdx {
              timeToRead
            }
          }
          author {
            name
            firstName
            lastName
          }
        }
      }
      contentfulGeneric(slug: { eq: "faq" }, node_locale: { eq: "nb-NO" }) {
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
              caption
            }
          }
          url
          secure_url
          width
          height
          format
        }
        author {
          name
          firstName
          lastName
          twitter
          twitterId
        }
        node_locale
        title
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      }
    }
  `)

  const {
    allContentfulInstruction: { nodes: allContent },
    contentfulGeneric: content,
  } = data
  const isAuthenticated = useIsAuthenticated()

  // allContent.sort((a, b) => a.order - b.order)

  // Parse the content and create the props needed by SEO component.
  // const helmetProps = getSeoProps(content, location, "article")
  // Parse the content, and get the needed banner props
  // const heroBannerProps = getBannerProps(content)

  return (
    <>
      <div>
        {/* <SEO {...helmetProps} /> */}
        {!content.public && isAuthenticated ? (
          <div className="relative  pb-20 px-4 sm:px-6  lg:pb-28 lg:px-8 max-w-7xl">
            <header className="lg:px-48">{/* <HeroSupportHeadline {...heroBannerProps} /> */}</header>
            <div className="">
              {allContent.map((instruction, index) => {
                return (
                  <FaqSection
                    key={instruction.id}
                    data={instruction}
                    imgPosition={index % 2 === 0 ? "left" : "right"}
                  />
                )
              })}
            </div>
          </div>
        ) : (
          <>Ikke noe innhold å vise</>
        )}
      </div>
    </>
  )
}

export default SupportFaq
