import { Link } from "gatsby-plugin-react-i18next"
import { ChartIcon, InvoiceIcon } from "./IconsMyPage"

export type BadgeProps = {
  title: string
  url: string
}

export type ButtonProps = {
  title: string
}

export const PrimaryBadgeClass =
  "inline-flex items-center text-button18 place-content-center font-normal text-black bg-white rounded-full border border-tomat-500 hover:text-white hover:bg-tomat-500 click:bg-red-900 click:text-white focus:bg-white focus:text-black focus:rounded-2xl disabled:text-white disabled:bg-sort-100 w-32 h-16"

/**
 * @name BadgeLink
 * @description This will create a URL to with link to @title
 * @author Frode Stenstrøm
 * @param title Text to display in a badge
 * @param url Link to use
 */

export const PrimaryBadgeLinkLarge = (props: BadgeProps) => {
  return (
    <Link
      to={props.url}
      className="inline-flex items-center px-8 py-4 
      text-button18 font-normal
      text-black bg-white
      rounded-full
      border 
      border-tomat-500
      hover:text-white hover:bg-tomat-500
      click:bg-red-900
      click:text-white
      focus:bg-white
      focus:text-black
      focus:rounded-2xl
      disabled:text-white
      disabled:bg-sort-100      
      "
    >
      {props.title}
    </Link>
  )
}

export const PrimaryBadgeLinkMedium = (props: BadgeProps) => {
  return (
    <Link
      to={props.url}
      className="inline-flex items-center px-6 py-4 
      text-button15 font-normal
      text-black bg-white
      rounded-full
      border 
      border-tomat-500
      hover:text-white hover:bg-tomat-500
      click:bg-red-900
      click:text-white
      focus:bg-white
      focus:text-black
      focus:rounded-full
      disabled:text-white
      disabled:bg-sort-100      
      "
    >
      {props.title}
    </Link>
  )
}

export const PrimaryBadgeLinkSmall = (props: BadgeProps) => {
  return (
    <Link
      to={props.url}
      className="inline-flex items-center px-3 py-4 
      text-button15 font-normal
      text-black bg-white
      rounded-full
      border 
      border-tomat-500
      hover:text-white hover:bg-tomat-500
      click:bg-red-900
      click:text-white
      focus:bg-white
      focus:text-black
      focus:rounded-full
      disabled:text-white
      disabled:bg-sort-100      
      "
    >
      {props.title}
    </Link>
  )
}

export const PrimaryBadgeLinkMediumThin = (props: BadgeProps) => {
  return (
    <Link
      to={props.url}
      className="inline-flex items-center px-6 py-2 
      text-button15 font-normal
      text-black bg-opacity-0
      rounded-full
      border 
      border-tomat-500
      hover:text-white hover:bg-tomat-500
      click:bg-red-900
      click:text-white
      focus:bg-white
      focus:text-black
      focus:rounded-full
      disabled:text-white
      disabled:bg-sort-100      
      "
    >
      {props.title}
    </Link>
  )
}

export const GreenBadge = (props: BadgeProps) => {
  return (
    <span className="inline-flex items-center px-3 py-1 rounded-full text-button1 bg-bggronsaker-500 text-sort-200">
      {props.title}
    </span>
  )
}

export const GreenBadgeButton = (props: BadgeProps) => {
  return (
    <Link
      to={props.url}
      className="inline-flex items-center  justify-center px-4 py-2 
       sm:text-button15  text-body14
      text-sort-200
      bg-knapplysgronn-500
      rounded-full
      hover:text-squash-500 
      hover:border
      hover: border-squash-500
      click:bg-red-900
      click:text-white
      focus:bg-knapplysgronn-500
      focus:text-black
      focus:rounded-full
      "
    >
      {props.title}
    </Link>
  )
}
// skal fjernes og erstattes med pdflinkbutton senere.
export const PdfLink = (props: BadgeProps) => {
  return (
    <div className="underline hover:text-tomat-500 ">
      <a href={props.url}>{props.title}</a>
    </div>
  )
}

export const PdfLinkButton = (props: ButtonProps) => {
  return (
    <button
      onClick={() => {
        console.log("laster ned pdf")
        // Default cookies are set already, so just update Consent.
      }}
      className="inline-flex items-center text-button18 place-content-center font-normal text-black underline hover:text-tomat-500 active:text-tomat-500 focus:bg-white focus:text-tomat-500"
    >
      {props.title}
    </button>
  )
}

export const ShortCutInvoice = (props: BadgeProps) => {
  return (
    <Link
      to={props.url}
      className="hover:bg-knapplysgronn-500 rounded-sm
      "
    >
      <div className="">
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              {/* Heroicon name: scale */}
              <InvoiceIcon />
            </div>
            <div className="ml-5 flex-1">
              <div className="text-body18 font-medium text-gray-900"> {props.title}</div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export const ShortCutProduction = (props: BadgeProps) => {
  return (
    <Link
      to={props.url}
      className="hover:bg-knapplysgronn-500 rounded-sm
      "
    >
      <div className="">
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              {/* Heroicon name: scale */}
              <ChartIcon />
            </div>
            <div className="ml-5 flex-1">
              <div className="text-body18 font-medium text-gray-900"> {props.title}</div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
